import React, { Component } from "react";
import { Link } from 'react-router-dom'
import "./styles.css";

class NotFound extends Component {
  render() {
    return (
      <div>
        <p>[404] Página não encontrada.</p> <br/>
        <Link to="/"> Voltar </Link>
      </div>
    );
  }
}

export default NotFound;