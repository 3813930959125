import React from 'react'


function Modal({isOpen, closeAction, children}) {

    if (isOpen) {
        return (
            <div className='Modal-background'> 
                <div className='Modal-style'>
                    {children}
                    <button id='btt-modal' onClick={closeAction}>Reiniciar</button>
                </div>
            </div>
        )
    }

    return null
}

export default Modal;