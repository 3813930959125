//var ENGLISH_WORDS = [
//    {word: "cárro", hint: "Anda com 4 rodas"},
//    {word: "cása", hint: "Onde nós moramos"},
//    {word: "avião", hint: "Viajamos pelos céus"},
//    {word: "bárco", hint: "Atravessamos as águas mais distantes"}
//  ];
  
const GENERAL_WORDS = require('./data.json');

const XPTO10_WORDS = require('./XPTO10.json');
const XPTO20_WORDS = require('./XPTO20.json');


function randomWord(workspace) {
  console.log("words: " + workspace);

  let data;

  switch(workspace) {
    case "XPTO10":
      data = XPTO10_WORDS[Math.floor(Math.random() * XPTO10_WORDS.length)];
      break;
    case "XPTO20":
      data = XPTO20_WORDS[Math.floor(Math.random() * XPTO20_WORDS.length)];
      break;
    default:
      data = GENERAL_WORDS[Math.floor(Math.random() * GENERAL_WORDS.length)];
  }

  return data
}

export { randomWord };
  