import { useState } from 'react';
import { randomWord } from './words';
import Modal from './components/modal';
import { useParams, redirect } from 'react-router-dom';

import "./Hangman.css";
import "./HangmanModal.css";
import "./HangmanResponsiveness.css";

import img0 from "./assets/0.png";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";
import img6 from "./assets/6.png";

import happy from "./assets/happy.png";
import sad from "./assets/sad.png";


function Hangman() {

  const { id } = useParams();
  let workpaceName;

  function loadWorkspace() {

    if (id === undefined) {
      console.log("Normal flow");
      return
    }

    const workspaces = require('./workspace.json');
    if (workspaces.hasOwnProperty(id)) {
      console.log("Valid workspace");

      workpaceName = workspaces[id];
      console.log(workpaceName);
      return
    }
    else {
      console.log("Redirect");
      workpaceName = "[Invalid]"
      redirect("/");
    }
  }

  loadWorkspace();

  let images = [img0, img1, img2, img3, img4, img5, img6];
  let maxWrong = 6;
  let answer = randomWord(id);
  const [state, setState] = useState({ nWrong: 0, guessed: new Set(), answer: answer.word, hint: answer.hint, playerWon: false, lastAnswer: ''});

  const [modalIsOpen, setIsOpen] = useState(false);

  function resetGame() {
    let result;
    state.nWrong === maxWrong ? result = false : result = true;

    let answer = randomWord(id);
    let lastAnswer = state.answer

    setState({
      nWrong: 0,
      guessed: new Set(),
      answer: answer.word,
      hint: answer.hint,
      playerWon: result,
      lastAnswer: lastAnswer
    });
  }

  function guessedWord() {
    const { answer, guessed } = state;

    return answer
      .split("")
      .map(ltr => (guessed.has(normalizeString(ltr)) ? ltr : "_"));
  }

  function handleGuess(evt) {
    let ltr = evt.target.value;

    setState(st => ({
      guessed: st.guessed.add(ltr),
      nWrong: st.nWrong + (answerHasLetter(ltr) ? 0 : 1),
      answer: state.answer,
      hint: state.hint
    }));
  }

  function generateButtons() {

    return "abcdefghijklmnopqrstuvwxyz".split("").map((ltr, index) => (
      <button
        key={index}
        value={ltr}
        onClick={handleGuess}
        disabled={state.guessed.has(ltr)}
      >
        {ltr}
      </button>
    ));
  }

  function isFinished() {
    if (state.answer === guessedWord().join("") || state.nWrong === maxWrong) {
      resetGame();
      return true
    }
    
    return false
  }

  function answerHasLetter(letter) {
    let normalized_answer = normalizeString(state.answer);

    if (normalized_answer.includes(letter)) {
      return true
    }

    return false
  }

  function normalizeString(string) {
    //Remove accents :: https://www.30secondsofcode.org/js/s/remove-accents/
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  let alternateText = `${state.nWrong}/${maxWrong} tentativas`;

  return (
    <div className='Hangman' style={{backgroundColor:'rgb(255,255,255)'}}>

      <Modal isOpen={modalIsOpen} closeAction={() => setIsOpen(!modalIsOpen)}>
        { state.playerWon ? 
          <>
            <div className='Modal-image'>
              <img src={happy} />
            </div>
            <div className='Modal-text'>
              <h3>Parabéns, você venceu o desafio!</h3>
            </div>
          </>
           : 
          <>
            <div className='Modal-image'>
              <img src={sad} /> 
            </div>
            <div className='Modal-text'>
              <h3>A resposta correta era {state.lastAnswer}.</h3>
            </div>
          </>
        }
      </Modal>

      <div className="image-place">
        <img id='main-image' src={images[state.nWrong]} alt={alternateText} title={alternateText}/>
      </div>
      <div className="game-place">
        { workpaceName ? 
          <>
              <p className='Hangman-title'>Sopa de Letrinhas - {workpaceName}</p>
          </>
           : 
          <>
              <p className='Hangman-title'>Sopa de Letrinhas</p>
          </>
        }
      
        <p className='Hangman-hint'> <b>Dica:</b> {state.hint}</p>

        { isFinished() ? setIsOpen(true) : null}

        <div>
          <p className='Hangman-word'>{guessedWord()}</p>
          <p className='Hangman-btns'>{generateButtons()}</p>
        </div>
      </div>

      <div className="footer">
        <a href="https://www.linkedin.com/in/ebsouza/">By: EBsouza</a>
      </div>

    </div>

    
  );
}

export default Hangman;
