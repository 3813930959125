import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Hangman from "./Hangman";
import NotFound from "./notFound";

function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Hangman/> }/>
                <Route path="/workspace/:id" element={ <Hangman/> }/>
                <Route path="*" element={ <NotFound/> }/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;